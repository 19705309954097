<template>
  <v-container>
    <h1 style="margin-bottom: 20px;">Infos</h1>


    <v-expansion-panels >
      <v-expansion-panel v-for="(item,i) in faq" :key="i">
        <v-expansion-panel-header>
          {{item.question}}
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pl-4">
          <div v-html="item.answer" style="display: block !important" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>

export default {
  name: 'FAQ',
  components: {
  },
  data: () => ({
    faq: null
  }),
  async created() {
    let temp = await this.$client.items("faq").readMany({ fields: ['*.*']})
    this.faq =  temp.data
  }

}
</script>